import { NgModule, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ElementRef, Input } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faSave, faTimes, faArrowDown, faArrowUp, faArrowRight, faArrowLeft, faPen, faFileImage, faStop, faPause, faPlay, faLock, faPlug, faPlus, faImages, faSync, faTable, faTrash, faEye, faClock, faInfo, faBolt, faFolderPlus, faCheck, faList, faLevelUpAlt, faPlayCircle, faFolderOpen, faVial, faVideo, faFilm, faCode, faPhotoVideo, faHome, faDesktop, faCalendar, faUsers, faFile, faUserTag, faUser, faCog, faSignOutAlt, faImage, faEyeSlash, faVideoSlash, faRedo, faCalendarAlt, faColumns } from '@fortawesome/free-solid-svg-icons';

/*
 **/
@Component({
  selector: 'icon',
  templateUrl: './icon.component.html'
})
export class IconComponent
{
  private icons = {
    ArrowLeft: faArrowLeft,
    ArrowRight: faArrowRight,
    ArrowUp: faArrowUp,
    ArrowDown: faArrowDown,
    Bolt: faBolt,
    Calendar: faCalendar,
    CalendarAlt: faCalendarAlt,
    Check: faCheck,
    Clock: faClock,
    Code: faCode,
    Cog: faCog,
    Columns: faColumns,
    Desktop: faDesktop,
    Eye: faEye,
    EyeSlash: faEyeSlash,
    File: faFile,
    FileImage: faFileImage,
    Film: faFilm,
    FolderOpen: faFolderOpen,
    FolderPlus: faFolderPlus,
    Home: faHome,
    Image: faImage,
    Images: faImages,
    Info: faInfo,
    List: faList,
    LevelUp: faLevelUpAlt,
    Lock: faLock,
    Pause: faPause,
    Pen: faPen,
    PhotoVideo: faPhotoVideo,
    Play: faPlay,
    PlayCircle: faPlayCircle,
    Plug: faPlug,
    Plus: faPlus,
    Redo: faRedo,
    Save: faSave,
    SignOut: faSignOutAlt,
    Stop: faStop,
    Sync: faSync,
    Table: faTable,
    Times: faTimes,
    Trash: faTrash,
    User: faUser,
    Users: faUsers,
    UserTag: faUserTag,
    Video: faVideo,
    VideoSlash: faVideoSlash
  };

  constructor(private el: ElementRef)
  {
  }

  // ----

  _icon: string;

  get icon(): string { return this._icon; }

  @Input('icon')
  set icon(_value: string)
  {
    this._icon = _value;
    if (_value === undefined || _value === null || _value.length === 0)
    {
      throw 'No icon has been passed!';
    }
  }
}

@NgModule({
  declarations: [
    IconComponent
  ],
  imports: [
    CommonModule,
    FontAwesomeModule
  ],
  exports: [
    IconComponent
  ]
})
export class IconModule { }
